// auth 

import { Notification } from '../events/notifications';

// skin

export type SkinResponse = {
  slug: string;
  phones: string[];
  emails: string[];
  su_enabled: boolean;
  language: string;
  login_notice: string;
  zone: string;
}

export async function GetSkin(): Promise<SkinResponse> {
  const response = await fetch('/api/v2.2/skin');
  if (!response.ok) {
    return { slug: 'unknown', phones: [], emails: [], su_enabled: false, language: 'en', login_notice: '', zone: '' };
  }
  const data = await response.json() as SkinResponse;
  return data;
}

// heartbeat

export type HBAgent = {
  daily: number;
  weekly: number;
  pending: number;
}

export type HBPlayer = {
  balance: number;
  available: number;
  fp_available: number;
  pending: number;
}

export type HeartbeatResponse = {
  skin: string;
  username: string;
  client_ip: string;
  permissions: string[];
  notifications: Notification[];
  msg_unread: number;
  msg_count: number;

  player: HBPlayer;
  agent: HBAgent;

  lang: string;
  theme: string;
  partnered: boolean;
  full_name: string;

  session_type: string;

  _valid: boolean;
}

async function apiFetchHeartbeat(): Promise<HeartbeatResponse> {
  try {
    const response = await fetch('/api/v2/auth/hb');
    if (!response.ok) {
      if (response.status == 401) {
        document.location.replace('/#/?expired=true');
      }
      console.log(`Error fetching heartbeat: ${response.statusText}`);
      return { _valid: false } as HeartbeatResponse;
    }
    const data = await response.json() as HeartbeatResponse;
    data._valid = true;
    return data;
  } catch (error) {
    console.error('Error fetching heartbeat:', error);
    return { _valid: false } as HeartbeatResponse;
  }
}

var hbF: Function = new Function();
var hbE: Function = new Function();
var hbCycle = 0;

function heartbeat() {
  hbCycle++;
  apiFetchHeartbeat()
    .then(data => {
      if (data._valid) {
        if (!data.username || data.username == '') {
          document.location.replace('/#/?expired=true');
        } else {
          hbF(data);
          var cssId = 'skin-css';  // you could encode the css path itself to generate id..
          if (!document.getElementById(cssId)) {
            var head = document.getElementsByTagName('head')[0];
            if (data.session_type != 'agent') {
              var link = document.createElement('link');
              link.id = cssId;
              link.rel = 'stylesheet';
              link.type = 'text/css';
              link.href = '/skins/' + data.skin + '/colors.css';
              link.media = 'all';
              head.appendChild(link);
            }
          }
        }
      } else {
        hbE();
      }
      setTimeout(heartbeat, 5000);
    });
}

export function InitializeHeartbeat(fsuc: Function, ferr: Function) {
  hbF = fsuc;
  hbE = ferr;
  heartbeat();
}
